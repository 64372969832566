import Facebook from "@mui/icons-material/Facebook"
import LocalPhone from "@mui/icons-material/LocalPhone"
import { Button, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"
export default function Contact() {
  return (
    <Grid container sx={{ background: "#fff", padding: "15px", gap: "5px" }}>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          sx={{ background: "#1877f2", color: "#fff", borderColor: "#fff" }}
          startIcon={<Facebook />}
          onClick={() => {
            window.open(
              "https://www.facebook.com/profile.php?id=61565511707854",
              "_blank"
            )
          }}
        >
          eventphotoshot
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          sx={{ background: "#06c755", color: "#fff", borderColor: "#fff" }}
          startIcon={
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/line-brands-solid.svg`}
              alt="Line"
              style={{ width: 20, height: "auto" }}
            />
          }
          onClick={() => {
            window.open("https://line.me/R/ti/p/@359osrsn", "_blank")
          }}
        >
          eventphotoshot
        </Button>
      </Grid>
    </Grid>
  )
}
